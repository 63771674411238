import * as React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import blogimage from "../images/inspire_you.jpg"
import iconOrange from "../images/icon-orange.png"

const blog = () => (
  <Layout>
    <SEO title="Blog" />
    <div class="container">
      <div class="p-4 p-md-5 mb-4 mt-5 text-dark rounded bg-coolorange">
        <div class="col-md-6 px-0">
          <h1 class="display-4 fst-italic">Technology Race To Where!</h1>
          <p class="lead my-3">Mar 18</p>
          <p class="lead my-3">Reading time: 3 minutes</p>
          <p class="lead mb-0">
            <Link to="/blog1" className="link-text text-decoration-underline">
              Continue reading...
            </Link>
          </p>
        </div>
      </div>

      <div class="row mb-2">
        <div class="col-md-6">
          <div class="row g-0 border rounded overflow-hidden flex-md-row mb-4 shadow-sm h-md-250 position-relative">
            <div class="col p-4 d-flex flex-column position-static">
              <img src={iconOrange} width="60" />
              <h3 class="mb-0">Catastrophic Financial Tsunami!</h3>
              <div class="mb-1 text-muted">Mar 16 | Reading Time: 3 mins</div>
              <p class="card-text mb-2">
                The relocation market has been stressed financially like no one
                could imagine prior to COVID. This is after years of pressure on
                profits, increased compliance, and investment in areas such as
                technology?
              </p>
              <Link to="/blog2" className="link-text text-decoration-underline">
                Continue reading...
              </Link>
            </div>
          </div>
        </div>

        <div class="col-md-6">
          <div class="row g-0 border rounded overflow-hidden flex-md-row mb-4 shadow-sm h-md-250 position-relative">
            <div class="col p-4 d-flex flex-column position-static">
              <img src={iconOrange} width="60" />
              <h3 class="mb-0">Do Your Providers Really Add Value?</h3>
              <div class="mb-1 text-muted">Mar 12 | Reading Time: 4 mins</div>
              <p class="card-text mb-2">
                Obviously, an important question! Does your Relocation service
                provider truly do everything they can to deliver the best
                service, evolve your program, and make your life easier?
              </p>
              <Link to="/blog3" className="link-text text-decoration-underline">
                Continue reading...
              </Link>
            </div>
          </div>
        </div>
      </div>

      <div class="row g-0 border rounded overflow-hidden flex-md-row mb-4 shadow-sm h-md-250 position-relative">
        <div class="col p-4 d-flex flex-column position-static">
          <img src={iconOrange} width="60" />
          <h3 class="mb-0">Open Dialogue And Not Suprises!</h3>
          <div class="mb-1 text-muted">Mar 09 | Reading Time: 3 mins</div>
          <p class="card-text mb-2">
            Are your providers good at communicating and sharing how their
            company is doing in these challenging times? Are you welcoming of
            their communication and see value in such dialogue?
          </p>
          <Link to="/blog4" className="link-text text-decoration-underline">
            Continue reading...
          </Link>
        </div>
      </div>

      <div class="p-4 p-md-5 mb-4 text-white rounded bg-coolblue">
        <div class="col-md-6 px-0">
          <h1 class="display-4 fst-italic">Ecosystem The Future!</h1>
          <p class="lead my-3">05/03/2021</p>
          <p class="lead my-3">Reading time: 2 minutes</p>
          <p class="lead mb-0">
            <Link
              to="/blog5"
              className="link-text-white text-decoration-underline"
            >
              Continue reading...
            </Link>
          </p>
        </div>
      </div>
    </div>
  </Layout>
)

export default blog
